import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { ProfileService } from '../../../../services/profile/profile.service';
import { UsersService } from '../../../../services/users/users/users.service';
import { UserPermissionsService } from '../../../../services/users/permissions/user-permissions.service';
import { SalesOrdersService } from '../../../../services/sales-orders/sales-orders.service';
import { CrownedUser } from '../../../../interfaces/sales-orders/statistics/crowned-user';
import { User } from '../../../../interfaces/users/users.interface';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    constructor(
        private Router: Router,
        private ProfileService:ProfileService,
        private UsersService: UsersService,
        private UserPermissionsService: UserPermissionsService,
        private SalesOrdersService: SalesOrdersService
    ) { }

    crownedUser?:CrownedUser

    // Animated Crown
    userIsCrowned:boolean | undefined = undefined;
	aniconCrown: AnimationOptions = {
        path: '/assets/animated/crown.json',
        loop: true,
    };

    ngOnInit(): void {
        this.isAdmin();
        this.detectActiveMenu();
        this.getCurrentUser();

        this.getCrowedUser()
        setInterval( () => {
            this.getCrowedUser()
        }, 600000 ) // Every 10 mins

    }

    currentUserId:any = false;

    getCurrentUserId() {
        this.currentUserId = this.UsersService.getCurrentUserId();
    }

    currentUser?:User
    async getCurrentUser() {
        this.UsersService.getCurrentUser()
        .then( (data:any) => {
            console.log(data)
            if ( data ) this.currentUser = data
        })
        .catch( () => {
            console.log(`Can't get current user.`)
        })
    }

    activeMenu:any = false;
    topPosition:number = 0;

    navigate( menu:string ) {

        this.topPosition = 0;

        if ( menu == 'dashboard' )          this.Router.navigateByUrl('/');
        if ( menu == 'products' )           this.Router.navigateByUrl('/products/list');
        if ( menu == 'orders' )             this.Router.navigateByUrl('/orders/list');
        if ( menu == 'customers' )          this.Router.navigateByUrl('/customers/list');
        if ( menu == 'users' )              this.Router.navigateByUrl('/users');
        if ( menu == 'profile' )            this.Router.navigateByUrl('/profile');
        if ( menu == 'media' )              this.Router.navigateByUrl('/media');
        if ( menu == 'shipping' )           this.Router.navigateByUrl('/shipping/options/list');
        if ( menu == 'invoices' )           this.Router.navigateByUrl('/economy/invoices');


        this.topDecidersSystem( menu );
        this.topDecidersProducts( menu );
        this.topDecidersInvoices( menu );
        this.topDecidersOrders( menu );
        this.topDecidersCustomers( menu );
        this.topDecidersShipping( menu );

        this.activeMenu = menu;
    }

    topDecidersShipping( menu:string, navigate:boolean = true ) {

        if ( menu == 'shipping/options/list' ) {
            if ( navigate ) this.Router.navigateByUrl('/shipping/options/list');
            this.topPosition = 0;
        }

        // if ( menu == 'orders/history' ) {
        //     if ( navigate ) this.Router.navigateByUrl('/orders/history');
        //     this.topPosition = 32;
        // }

    }

    topDecidersOrders( menu:string, navigate:boolean = true ) {

        if ( menu == 'orders/list' ) {
            if ( navigate ) this.Router.navigateByUrl('/orders/list');
            this.topPosition = 0;
        }

        if ( menu == 'orders/history' ) {
            if ( navigate ) this.Router.navigateByUrl('/orders/history');
            this.topPosition = 32;
        }

    }

    topDecidersProducts( menu:string, navigate:boolean = true ) {

        if ( menu == 'products/list' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/list');
            this.topPosition = 0;
        }

        if ( menu == 'products/categories' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/categories');
            this.topPosition = 29 * 1;
        }

        if ( menu == 'products/prices' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/prices');
            this.topPosition = 29 * 2;
        }
        
        if ( menu == 'products/currencies' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/currencies');
            this.topPosition = 29 * 3;
        }

        // if ( menu == 'products/create' ) {
        //     if ( navigate ) this.Router.navigateByUrl('/products/create');
        //     this.topPosition = 29 * 4;
        // }

        if ( menu == 'products/article-groups' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/article-groups');
            this.topPosition = 29 * 4;
        }

        if ( menu == 'products/attributes' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/attributes');
            this.topPosition = 29 * 5;
        }

        if ( menu == 'products/discount-groups' ) {
            if ( navigate ) this.Router.navigateByUrl('/products/discount-groups');
            this.topPosition = 29 * 6;
        }
    }

    topDecidersInvoices( menu:string, navigate:boolean = true ) {

        if ( menu == 'economy/invoices' ) {
            if ( navigate ) this.Router.navigateByUrl('/economy/invoices');
            this.topPosition = 0;
        }

    }

    topDecidersCustomers( menu:string, navigate:boolean = true ) {

        if ( menu == 'customers/list' ) {
            if ( navigate ) this.Router.navigateByUrl('/customers/list');
            this.topPosition = 0;
        }

        if ( menu == 'customers/management/assign-users' ) {
            if ( navigate ) this.Router.navigateByUrl('/customers/management/assign-users');
            this.topPosition = 29 * 1;
        }

    }

    topDecidersSystem( menu:string, navigate:boolean = true ) {

        if ( menu == 'system/settings' ) {
            if ( navigate ) this.Router.navigateByUrl('/system/settings');
            this.topPosition = 0;
        }

        if ( menu == 'system/executor' ) {
            if ( navigate ) this.Router.navigateByUrl('/system/executor');
            this.topPosition = 29 * 1;
        }

        if ( menu == 'system/cache' ) {
            if ( navigate ) this.Router.navigateByUrl('/system/cache');
            this.topPosition = 29 * 2;
        }

        if ( menu == 'system/sync' ) {
            if ( navigate ) this.Router.navigateByUrl('/system/sync');
            this.topPosition = 29 * 3;
        }

        if ( menu == 'system/logs' ) {
            if ( navigate ) this.Router.navigateByUrl('/system/logs');
            this.topPosition = 29 * 4;
        }
        
    }

    detectActiveMenu() {

        if ( this.Router.url === undefined ) return;
        const url:string = this.Router.url;

        if ( url.includes('/') )                this.activeMenu = 'dashboard';
        if ( url.includes('/products/') )       this.activeMenu = 'products';
        if ( url.includes('/sales-orders') )    this.activeMenu = 'sales-orders';
        if ( url.includes('/web-orders') )      this.activeMenu = 'web-orders';
        if ( url.includes('/users') )           this.activeMenu = 'users';
        if ( url.includes('/profile') )         this.activeMenu = 'profile';
        if ( url.includes('/media') )           this.activeMenu = 'media';
        
        if ( url.includes('customers/list') )           this.activeMenu = 'customers/list';
        if ( url.includes('customers/sales-history') )  this.activeMenu = 'customers/sales-history';
        
        if ( url.includes('system/settings') )          this.activeMenu = 'system/settings';
        if ( url.includes('system/executor') )          this.activeMenu = 'system/executor';
        if ( url.includes('system/cache') )             this.activeMenu = 'system/cache';
        if ( url.includes('system/sync') )              this.activeMenu = 'system/sync';
        if ( url.includes('system/logs') )              this.activeMenu = 'system/logs';
        
        if ( url.includes('products/list') )            this.activeMenu = 'products/list';
        if ( url.includes('products/prices') )          this.activeMenu = 'products/prices';
        if ( url.includes('products/currencies') )      this.activeMenu = 'products/currencies';
        // if ( url.includes('products/create') )          this.activeMenu = 'products/create';
        
        if ( url.includes('orders/list') )              this.activeMenu = 'orders/list';
        if ( url.includes('orders/history') )           this.activeMenu = 'orders/history';
        
        if ( url.includes('shipping/options/list') )    this.activeMenu = 'shipping/options/list';

        if ( url.includes('/economy/invoices') )       this.activeMenu = 'invoices';

        this.topDecidersProducts( this.activeMenu, false );
        this.topDecidersSystem( this.activeMenu, false );
        this.topDecidersOrders( this.activeMenu, false );
        this.topDecidersShipping( this.activeMenu, false );
        this.topDecidersInvoices( this.activeMenu, false )
    }

    userSystemAdmin:boolean = false;
    userCustomerAdmin:boolean = false;
    userSalesManager:boolean = false;
    userSalesStaff:boolean = false;
    jivevillageDunderAdmin: boolean = false

    async isAdmin() {
        // TODO CHANGED THIS
        this.UserPermissionsService.isSystemAdmin().then( (data:any) => {
            this.userSystemAdmin = data
            // this.userSystemAdmin = true
        })

        this.UserPermissionsService.isCustomerAdmin().then( (data:any) => {
            this.userCustomerAdmin = data
            // this.userCustomerAdmin = true
        })

        this.UserPermissionsService.isSalesManager().then( (data:any) => {
            this.userSalesManager = data
            // this.userSalesManager = true
        })

        this.UserPermissionsService.isSalesStaff().then( (data:any) => {
            this.userSalesStaff = data
            // this.userSalesManager = true
        })

        this.UsersService.getCurrentUser().then( (data:any) => {
            if ( data?.emailAddress == 'par@condorverktyg.se' || data?.emailAddress == 'par@personverktyg.se' ) this.jivevillageDunderAdmin = true
        }).catch( () => {})
    }

    getCrowedUser() {
        this.SalesOrdersService.crownedMonthly().then( (data:any) => {

            if ( data && data.crowned !== undefined ) {
                this.crownedUser = data
                this.userIsCrowned = data.crowned
            }

        }).catch( () => {
            this.userIsCrowned = false
        })
    }
  
}